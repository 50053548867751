<template>
  <div class="courseDetailHeaderCls">
    <div
      class="courseDetailHeader"
      :style="{
        'background-image': `url(${imgSrc})`,
      }"
    >
      <icon name="course-bg2" class="bg2" />
      <icon name="course-sp-bg2" class="sp_bg2" />
      <div class="courseTitle">{{ props.name }}</div>
      <div class="courseSubTitle">
        {{ props.overview }}
      </div>
      <div class="courseSubTitleSp">
        {{ props.overview }}
      </div>
      <div class="shareContainer">
        <!-- <span class="shareText">Share</span>
      <icon class="shareIcon" name="share-icon"/> -->
        <shareBtn />
      </div>
    </div>
  </div>
</template>

<script>
import shareBtn from "@/components/CommonShare/CommonShare.vue";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    name: {
      default: "",
    },
    overview: {
      default: "",
    },
    courseId: {
      default: 1,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
  computed: {
    imgSrc() {
      return require(`../../assets/img/detail-bg${(this.courseId%5+1)}.png`)
    },
  },
  components: {
    shareBtn,
  },
});
</script>

<style lang="scss">
.courseDetailHeaderCls {
  .courseDetailHeader {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 1316px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    .bg2 {
      width: 1267px;
      height: 303px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .sp_bg2 {
      display: none;
    }
    .courseTitle {
      margin-top: 100px;
      margin-left: 65px;
      font-family: "Roboto Condensed", "Noto Sans JP", sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 70px;
      letter-spacing: 0.05em;
      color: $black;
    }
    .courseSubTitle {
      margin-top: 22px;
      margin-left: 65px;
      margin-right: 100px;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-align: left;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    .courseSubTitleSp {
      display: none;
    }
    .shareContainer {
      z-index: 1;
      align-self: flex-end;
      margin-top: 20px;
      margin-right: 115px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      white-space: nowrap;
      .common-share {
        .common-share-container {
          .common-share-text {
            width: 49px;
            justify-content: center;
          }
          .common-share-icon {
            width: 18px;
            height: 18px;
          }
          .common-share-icon-active {
            width: 18px;
            height: 18px;
          }
        }
      }
      // .shareText {
      //   width: 49px;
      //   cursor: pointer;
      //   font-family: "Roboto Condensed", sans-serif;
      //   font-weight: 700;
      //   font-size: 14px;
      //   line-height: 16px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   letter-spacing: 0.08em;
      //   color: $black;
      // }
      // .shareIcon {
      //   cursor: pointer;
      //   margin-left: 16px;
      //   width: 18px;
      //   height: 18px;
      // }
    }
  }
  @include mq(sm) {
    .courseDetailHeader {
      width: calc(100% - 20px);
      height: 402px;
      .bg2_1 {
        display: none;
      }
      .sp_bg2_1 {
        display: block;
        width: 336px;
        height: 402px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .bg2 {
        display: none;
      }
      .sp_bg2 {
        display: block;
        width: 336px;
        height: 329px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
      .courseTitle {
        margin: 80px 30px 0;
        font-size: 30px;
        line-height: 50px;
        letter-spacing: 0.05em;
        color: $black;
      }
      .courseSubTitle {
        display: none;
      }
      .courseSubTitleSp {
        display: block;
        height: 75px;
        margin: 24px 30px 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.1em;
        color: $dark-grey;
      }
      .shareContainer {
        width: 66px;
        margin-top: 0px;
        margin-right: 20px;
        padding-right: 3px;
        .common-share {
          .common-share-container {
            .common-share-text {
              width: 37px;
            }
            .common-share-icon {
              width: 18px;
              height: 18px;
              margin-left: 8px;
            }
            .common-share-icon-active {
              width: 18px;
              height: 18px;
              margin-left: 8px;
            }
          }
        }
        .shareText {
          cursor: pointer;
          width: 37px;
          font-family: "Roboto Condensed", sans-serif;
          font-weight: 700;
          font-size: 14px;
          line-height: 100%;
          display: flex;
          align-items: flex-start;
          letter-spacing: 0.08em;
          color: $black;
        }
        .shareIcon {
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
