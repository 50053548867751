<template>
  <div class="courseDetail" v-if="viewState.isDisplayed">
    <div class="headerDiv">
      <courseHeader
        :name="viewState.courseDetail.name"
        :overview="viewState.courseDetail.overview"
        :courseId="viewState.courseDetail.id"
      />
    </div>

    <div class="topImgDiv">
      <img :src="topImage" class="topImg" />
      <img :src="topImage" class="topImgSp" />
      <icon name="bg-dot-circle" class="imgBg" />
    </div>

    <template v-if="isEnterprise">
      <div class="course">
        <customCourseDetailLecture
          v-if="viewState.courseDetail.lectures.length > 0"
          title="講座一覧"
          class="custom-course-lecture-list"
          :lectures="viewState.courseDetail.lectures"
          @bookmark="bookmark"
        />
      </div>
    </template>

    <template v-else>
      <template v-for="id in viewState.courseDetail.lecture_level_ids" :key="id">
        <div class="course">
          <commonSwiperVideo
            :cid="id"
            :title="`${viewState.courseDetail.name}　${viewState.levels[id].name}`"
            subTitle=""
            :videos="viewState.lecturesByLevel[id]"
            :levels="viewState.levels"
            ref="commonSwiperVideo"
          />
        </div>
      </template>
    </template>

    <div class="keywordContainer">
      <div class="keywords">
        <keywords :keyList="viewState.courseKeywords" />
      </div>
      <div class="greyRound" />
    </div>

    <template v-if="! isEnterprise">
      <relatedVideos
        v-if="viewState.relatedVideos.length > 0"
        :videos="viewState.relatedVideos"
        :levels="viewState.levels"
      />
    </template>
  </div>
</template>

<script>
import util from "@/utils/util";
import http from "@/utils/http";
import courseHeader from "@/views/course/courseDetailHeader.vue";
import customCourseDetailLecture from "@/views/course/customCourseDetailLecture.vue";
import keywords from "@/components/KeyWords/KeyWords.vue";
import relatedVideos from "@/components/CommonRelatedVideos/RelatedVideos.vue";
import commonSwiperVideo from "@/components/CommonSwiperVideo/CommonSwiperVideo.vue";
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElLoading } from "element-plus";

export default defineComponent({
  components: {
    courseHeader,
    keywords,
    relatedVideos,
    commonSwiperVideo,
    customCourseDetailLecture,
  },
  setup() {
    const store = useStore();
    const isLogin = computed(() => store.state.user.isLogin);
    const router = useRouter();
    const isEnterprise = computed(() => store.state.user.isEnterprise)

    const viewState = reactive({
      response: {
        status: 0,
        data: {},
      },
      isDisplayed: false,
      courseCode: location.pathname.split("/").slice(-1)[0],
      courseDetail: {
        name: "",
        overview: "",
        levels: {},
      },
      relatedVideos: {},
      courseKeywords: [],
      lecturesByLevel: {},
      levels: [],
      isExistRelatedVideos: false,
    });

    // method
    const topImage = computed(() => {
      if (isEnterprise.value || viewState.courseDetail.id === 1) {
        // エンタープライズの場合、固定でコモンコアの画像を表示する
        return require('../../assets/img/detail-bg-common-core.png')
      } else if (viewState.courseDetail.id === 2) {
        return require('../../assets/img/detail-bg-digital-literacy.png')
      } else if (viewState.courseDetail.id === 3) {
        return require('../../assets/img/detail-bg-marketing-literacy.png')
      } else if (viewState.courseDetail.id === 4) {
        return require('../../assets/img/detail-bg-technology-literacy.png')
      } else if (viewState.courseDetail.id === 5) {
        return require('../../assets/img/detail-bg-creative-literacy.png')
      } else {
        // 共通でコモンコアの画像を使う
        return require('../../assets/img/detail-bg-common-core.png')
      }
    });

    window.scrollTo(0, 0);

    const courseContainerMouseover = (item, index) => {
      viewState.courseHoveredBgImgUrl = item.hoveredBgImgUrl;
      viewState.courseHovered = true;
      viewState.hoverIndex = index;
    };

    const fetchCourseDetail = async (courseCode) => {
      return await http
        .get(`/api/courses/${courseCode}?key_type=code`)
        .then((response) => {
          console.dir(response.data);
          viewState.courseDetail = response.data;

          // keywordをキーにして重複を取り除く
          const uniqueKeywordObject = util.keyBy(
            response.data.lecture_keywords,
            "keyword"
          );
          viewState.courseKeywords = Object.values(uniqueKeywordObject);
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };

    const fetchLecturesRelatedCourseKeywords = async (courseId) => {
      return await http
        .get(
          `/api/lectures?course_id=${courseId}&keyword_related=1&order=1&res_ptn=1&count=30`
        )
        .then((response) => {
          console.dir(response.data);
          viewState.relatedVideos = response.data;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };

    const fetchLevels = () => {
      return http
        .get("/api/levels")
        .then((response) => {
          viewState.levels = util.keyBy(response.data, "id");
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };

    const fetchLecturesByLevel = async (courseId, levelId) => {
      return await http
        .get(`/api/lectures/?course_id=${courseId}&level=${levelId}&count=30`)
        .then((response) => {
          console.dir(response.data);
          const lectures = response.data;
          viewState.lecturesByLevel = {
            ...viewState.lecturesByLevel,
            [levelId]: lectures,
          };

          return response;
        })
        .catch((error) => {
          return error.response;
        });
    };
  
    const fetchCustomCourseDetail = async (courseCode) => {
      return await http
        .get(`/api/custom-courses/${courseCode}?key_type=code`)
        .then((response) => {
          console.dir(response.data);
          viewState.courseDetail = response.data;

          viewState.courseKeywords = response.data.keywords;
          return response;
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          return error.response;
        });
    };

    const bookmark = (lectureKey, isFavorite) => {
      viewState.courseDetail['lectures'].forEach((lecture) => {
        if (lecture.key == lectureKey) {
          lecture.is_favorite = isFavorite;
        }
      });
    }

    const reInit = async () => {
      console.log("re-init");
      init();
    };

    const init = async () => {
      if (isEnterprise.value) {
        viewState.response = await fetchCustomCourseDetail(viewState.courseCode);
        if (viewState.response.status >= 400) {
          // エラーの場合は一覧画面
          router.push({
            name: "course",
          });
          return;
        }
      } else {
        // レベル一覧取得
        fetchLevels();

        // コース詳細取得
        viewState.response = await fetchCourseDetail(viewState.courseCode);
        if (viewState.response.status >= 400) {
          // エラーの場合は一覧画面
          router.push({
            name: "course",
          });
          return;
        }

        // 難易度ごとの講座取得
        await Promise.all(
          viewState.courseDetail.lecture_level_ids.map(async (levelId) => {
            await fetchLecturesByLevel(viewState.courseDetail.id, levelId);
          })
        );

        // 関連講座取得
        await fetchLecturesRelatedCourseKeywords(viewState.courseDetail.id);
      }

      viewState.isDisplayed = true; // 表示OK
    };

    onMounted(async () => {
      if (isLogin.value && store.state.user.servicePlanId === 0) {
        // ログイン済み、かつサーピスプラン未取得の場合、
        // 顧客情報を取得したタイミングでコース情報を取得する
        console.log("skip on-mount process");
        return;
      }

      init();
    });

    const loading = ElLoading.service({
      lock: true,
      text: "Loading...",
      background: "#fff",
    });
    watch(
      () => viewState.isDisplayed,
      () => {
        loading.close();
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        await reInit();
      }
    );

    return {
      util,
      viewState,
      isEnterprise,
      topImage,

      // method
      courseContainerMouseover,
      bookmark,
    };
  },
});
</script>

<style lang="scss" scoped>
.courseDetail {
  max-width: 1366px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  .headerDiv {
    width: 100%;
    height: fit-content;
    position: absolute;
    margin-left: 50px;
  }
  .topImgDiv {
    margin-top: 302px;
    margin-left: 50px;
    margin-bottom: 65px;
    max-width: 1266px;
    max-height: 500px;
    position: relative;
    .topImg {
      width: 100%;
      height: 100%;
    }
    .topImgSp {
      display: none;
      width: 100%;
      height: 100%;
    }
    .imgBg {
      position: absolute;
      width: 620px;
      height: 620px;
      bottom: -310px;
      left: -360px;
      z-index: -1;
    }
  }
  .divider {
    align-self: center;
    margin-top: 15px;
    margin-bottom: 80px;
    width: 1136px;
    height: 0px;
    border-bottom: 0.5px solid $black-50;
  }
  .spdivider {
    display: none;
  }
  .course {
    z-index: 0;
    max-width: 1316px;
    margin-left: 50px;
    @include mq(sm) {
      margin-left: 20px;
    }
    .common-swiper-video {
      .common-title {
        .common-title-area {
          margin-right: 220px;
          align-items: center;
          .common-titles-title {
            white-space: pre-wrap;
            text-align: left;
          }
          .common-titles-sub-title {
            width: unset;
            line-height: unset;
            padding-top: 2px;
          }
        }
      }
    }

    .custom-course-lecture-list {
      margin-bottom: 65px;
    }
  }
  .keywordContainer {
    position: relative;
    margin-top: 80px;
    .keywords {
      max-width: 1316px;
      padding: 65px 65px 0px 50px;
      background: $white;
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
    }
    .greyRound {
      width: 584px;
      height: 584px;
      background: $grey;
      border-radius: 50%;
      position: absolute;
      left: -292px;
      top: -292px;
      z-index: -1;
    }
  }
  @include mq(sm) {
    .key-words .key-words-title-area {
      margin-left: 20px;
    }
    .key-words .key-words-btns {
      margin-left: 50px;
    }
    .headerDiv {
      margin-left: 20px;
    }
    .topImgDiv {
      margin-top: 319px;
      margin-left: 20px;
      margin-bottom: 60px;
      width: 335px;
      height: 165px;
      .topImg {
        display: none;
        width: 100%;
        height: 100%;
      }
      .topImgSp {
        display: block;
        width: 100%;
        height: 100%;
      }
      .imgBg {
        position: absolute;
        width: 282px;
        height: 282px;
        bottom: -141px;
        left: -160px;
        z-index: -1;
      }
    }
    .divider {
      align-self: center;
      margin: 60px 50px;
      width: calc(100% - 100px);
      height: 0px;
      border-bottom: 0.5px solid $black-50;
    }
    .spdivider {
      display: flex;
      align-self: center;
      margin-top: 60px;
      margin-bottom: 60px;
      width: 275px;
      height: 0px;
      border-bottom: 0.5px solid $black-50;
    }
    .course {
      .custom-course-lecture-list {
        margin-bottom: 60px;
      }
    }
    .course_primary {
      margin-left: 20px;
      .common-swiper-video {
        .common-title {
          &::before {
            top: 36px;
          }
          .common-title-area {
            margin-right: unset;
            align-items: unset;
            .common-titles-title {
              width: 305px;
              white-space: unset;
              display: block;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              text-overflow: ellipsis;
            }
            .common-titles-sub-title {
              width: 300px;
              padding-top: 0px;
            }
          }
        }
      }
    }
    .course_intermediate {
      margin-left: 20px;
      .common-swiper-video {
        .common-title {
          &::before {
            top: 36px;
          }
          .common-title-area {
            margin-right: unset;
            align-items: unset;
            .common-titles-title {
              width: 305px;
              white-space: unset;
              display: block;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              text-overflow: ellipsis;
            }
            .common-titles-sub-title {
              width: 300px;
              padding-top: 0px;
            }
          }
        }
      }
    }
    .course_senior {
      margin-left: 20px;
      .common-swiper-video {
        .common-title {
          &::before {
            top: 36px;
          }
          .common-title-area {
            margin-right: unset;
            align-items: unset;
            .common-titles-title {
              width: 307px;
              white-space: unset;
              display: block;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              text-overflow: ellipsis;
            }
            .common-titles-sub-title {
              width: 300px;
              padding-top: 0px;
            }
          }
        }
      }
    }
    .keywordContainer {
      position: relative;
      margin-top: 0px;
      .keywords {
        margin-right: 20px;
        padding: 40px 0 0 0;
        background: $white;
        box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      }
      .greyRound {
        width: 255px;
        height: 255px;
        left: -127px;
        top: -128px;
      }
    }
  }
}
</style>
